<template>
  <div v-if="isshowgrid">
    <va-card title="User List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-button color="success" icon="fa fa-plus" v-if="adminRole || createProvision" @click="add()">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" v-if="adminRole || createProvision" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Organization</span>
            <va-select
              v-if = "isCreate"
              placeholder="Select Organization Name"
              v-model="org_name"
              searchable
              textBy="org_name"
              :options="orgArr"
              :error="!!orgNameErrors.length"
              :error-messages="orgNameErrors"
              @input="getOrgData(org_name)"
              noClear
            />
            <va-input
              v-if = "isUpdate"
              v-model="org_name"
              type="text"
              :disabled="true"
            />
            <span v-if="isCreate && orgFlag" class="va-form-label va-form-required-label">Role</span>
            <va-select
              v-if = "isCreate && orgFlag"
              placeholder="Select Role"
              v-model="role"
              textBy="id"
              :options="role_obj"
              :error="!!roleErrors.length"
              :error-messages="roleErrors"
              @input="roleData(role)"
              noClear
            />
            <span v-if="isUpdate" class="va-form-label va-form-required-label">Role</span>
            <va-input
              v-if = "isUpdate"
              v-model="role"
              type="text"
              :disabled="true"
            />
            <div v-if = "isCreate && roleFlag">
              <span class="va-form-label va-form-required-label">Provider</span>
              <va-select
                v-if = "isCreate && roleFlag"
                placeholder="Select Provider"
                v-model="provider"
                textBy="brand"
                :options="providerArr"
                :error="!!providerErrors.length"
                :error-messages="providerErrors"
                noClear
              />
            </div>
            <div v-if = "isUpdate && role == 'PROVIDER'">
              <span class="va-form-label va-form-required-label">Provider</span>
              <va-select
                placeholder="Select Provider"
                v-model="provider"
                textBy="brand"
                :options="providerArr"
                :error="!!providerErrors.length"
                :error-messages="providerErrors"
                noClear
              />
            </div>
            <span class="va-form-label va-form-required-label">User Name</span>
            <va-input
              v-if = "isCreate"
              v-model.trim="username"
              type="text"
              placeholder="Enter User Name"
              pattern="^[a-z0-9]+$"
              :error="!!userNameErrors.length"
              :error-messages="userNameErrors"
              :required="true"
            />
            <va-input
              v-if = "isUpdate"
              v-model="username"
              type="text"
              :disabled="true"
            />
            <span class="va-form-label va-form-required-label">Password (Minimum length should be 8)</span>
            <va-input
              v-model.trim="password"
              type="password"
              placeholder="Enter Password"
              pattern="[A-Za-z0-9_@!./#&+-^]{8,}$"
              :error="!!passwordErrors.length"
              :error-messages="passwordErrors"
            />
            <span class="va-form-label va-form-required-label">First Name</span>
            <va-input
              v-model.trim="first_name"
              type="text"
              placeholder="Enter First Name"
              pattern="^[A-Za-z0-9_-]+(?: +[A-Za-z0-9_-]+)*$"
              :error="!!firstNameErrors.length"
              :error-messages="firstNameErrors"
            />
            <span class="va-form-label">Last Name</span>
            <va-input
              v-model.trim="last_name"
              type="text"
              placeholder="Enter Last Name"
              pattern="^[A-Za-z0-9_-]+(?: +[A-Za-z0-9_-]+)*$"
              :error="!!lastNameErrors.length"
              :error-messages="lastNameErrors"
            />
            <span class="va-form-label va-form-required-label">Mobile Number</span>
            <va-input
              v-model.trim="phone_number"
              type="telphone"
              pattern="[6-9]{1}[0-9]{9}"
              maxlength="10"
              placeholder="Enter Mobile Number"
              :error="!!mobileNumberErrors.length"
              :error-messages="mobileNumberErrors"
            />
            <span class="va-form-label va-form-label">Partner Code</span>
            <va-input
              v-model.trim="partner_code"
              type="text"
              pattern="^[A-Za-z0-9]+$"
              maxlength="10"
              placeholder="Enter Partner Code"
            />
            <va-checkbox
              v-model="status"
              label="Status"
            />
            <!-- <label><input type="checkbox" class="checkbox" v-model="status">Status</label> -->

            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createUser()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateUser()">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
import REGEX from '../../i18n/regex_pattern'
Vue.use(vueResource)
export default {
  name: 'org',
  created () {
    this.getAllUser()
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      showRemoveModal: false,
      status: false,
      orgFlag: false,
      roleFlag: false,
      createProvision: false,
      adminRole: true,
      last_name: '',
      first_name: '',
      org_name: '',
      role: '',
      provider: '',
      password: '',
      username: '',
      phone_number: '',
      title: '',
      orgArr: [],
      providerArr: [],
      userData: [],
      orgNameErrors: [],
      roleErrors: [],
      providerErrors: [],
      lastNameErrors: [],
      firstNameErrors: [],
      userNameErrors: [],
      mobileNumberErrors: [],
      passwordErrors: [],
      partner_code:''
    }
  },
  computed: {
    checkpassword () {
      if (this.password.length >= 8) {
        return true
      } else {
        return false
      }
    },
    formReady () {
      return !this.userNameErrors.length && !this.firstNameErrors.length && !this.roleErrors.length && !this.mobileNumberErrors.length && !this.providerErrors.length
    },
    fields () {
      return [{
        name: 'sno',
        title: 'S.NO',
        width: '6%',
      }, {
        name: 'username',
        title: 'User Name',
      },
      {
        name: 'roles',
        title: 'Role',
      },
      {
        name: 'phone_number',
        title: 'Phone Number',
      },
      {
        name: 'first_name',
        title: 'First Name',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.userData)
    },
  },
  methods: {
    getOrgData (org_name) {
      this.role_obj = []
      this.role = ''
      this.orgFlag = true
      if(this.org_name.org_type === null){
        this.role_obj = [{id: 'SALES'},{id: 'SUPPORT'},{id: 'ADVANCE SUPPORT'},{id: 'FINANCE'},{id:'PROVIDER'}]
        this.org_update_flag = true
      }else{
        this.role_obj = [{ id: org_name.org_type }]
        this.org_update_flag = false
      }
    },
    roleData (data) {
      if (data.id == 'PROVIDER') {
        this.roleFlag = true
      }else{
        this.roleFlag=false
      } 
      
    },
    getAllUser () {
      var role = this.$cookies.get('userRole')
      if (role === 'ADMIN') {
        this.adminRole = true
        this.createProvision = true
      } else {
        this.adminRole = false
      }
      this.$http.get(config.menu.host + '/user').then(response => {
        let index = 0
        this.userData = []
        var vm = this;
        response.body.map(function (item) {
          item.id = index++
          item.sno = index
          item.is_existing = true
          item.checkbox_value = false
          if(!vm.adminRole && (item.roles == 'OPERATOR' || item.roles == 'HEPI_OPERATOR' || item.roles == 'RESELLER' || item.roles == 'HEPI_RESELLER' || item.roles == 'SUPPORT' )){
            vm.userData.push(item)
          }else if(vm.adminRole){
            vm.userData.push(item)
          }
        })
        this.isshowForm = false
        this.isshowgrid = true
        var ref = this
        this.$http.get(config.menu.host + '/org').then(response => {
          response.body.map(function (thing) {
            if (thing.org_type === 'RESELLER' || thing.org_type === 'HEPI_RESELLER') {
              ref.createProvision = thing.sub_operators
            }
            ref.orgArr = response.body
            ref.orgArr.map(function (arg, i) { arg.id = i + 1 })
            ref.orgArr = ref.orgArr.filter(function (prop) {
              return (prop.org_type !== 'ADMIN')
            })
          })
        })
        this.$http.get(config.menu.host + '/provider').then(resp => {
          resp.body = resp.body.filter(function(prop,i){
            prop['id']=i+1
            return prop.iptv
          })
          this.providerArr = resp.body
        })
      })
    },
    createUser () {
      this.userNameErrors = this.username ? [] : ['User Name is required']
      this.firstNameErrors = this.first_name ? [] : ['First Name is required']
      this.passwordErrors = this.password ? [] : ['Password is required']
      this.mobileNumberErrors = this.phone_number ? [] : ['Phone Number is required']
      this.roleErrors = this.role ? [] : ['Role is required']
      if(this.roleFlag){
        console.log("this.role-------",this.role)
        this.providerErrors = this.provider ? [] : ['Provider is required']
      }else{
        console.log("else----------",this.roleFlag)
        this.provider=''
        this.providerErrors=''
        this.roleFlag=false
      }
     // this.providerErrors = this.provider ? [] : ['Provider']
      if (!this.checkpassword) {
        Vue.notify({ text: 'Password Length must 8 Characters', type: 'error' })
        return
      }
      if (!this.formReady) {
        return
      }
      if (!REGEX.smallAndNumber(this.username)) {
        return this.userNameErrors.push('Only Lower Case and Numbers are allowed')
      }
      // var provider_id = this.provider.provider_id
      // var brand = this.provider.brand
      var payload = {
        org_name: this.org_name.org_name,
        org_id: this.org_name.org_id,
        reseller_org_id: this.org_name.reseller_org_id,
        username: this.username,
        roles: this.role.id,
        mso_provider_id: this.provider.provider_id ,
        mso_provider_brand: this.provider.brand,
        password: this.password,
        first_name: this.first_name,
        last_name: this.last_name,
        status: this.status,
        phone_number: this.phone_number,
        org_update_flag: this.org_update_flag,
        partner_code: this.partner_code
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/user', payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updateUser () {
      this.firstNameErrors = this.first_name ? [] : ['First Name is required']
      this.mobileNumberErrors = this.phone_number ? [] : ['Phone Number is required']
      if (this.password && !this.checkpassword) {
        Vue.notify({
          text: 'Password Length must 8 Characters',
          type: 'error',
        })
        return
      }
      if (!this.formReady) {
        return
      }
      var payload = {  
        username: this.username,
        role: this.role,
        last_name: this.last_name,
        first_name: this.first_name,
        phone_number: this.phone_number,
        partner_code: this.partner_code,
        status: this.status,
        mso_provider_id: (this.provider) ? (this.provider.provider_id ) : '',
        mso_provider_brand:  (this.provider) ? this.provider.brand: '',
        partner_code: this.partner_code
      }
      if (this.password) {
        payload.password = this.password
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/user/' + this.user_id, payload).then(resp => {
        loader.hide()
        if (resp && resp.body) {
          Vue.notify({ text: resp.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    add () {
      var role = this.$cookies.get('userRole')
      if (role === 'ADMIN') {
        this.role_obj = [{ id: 'RESELLER' }, { id: 'OPERATOR' }, { id: 'HEPI_RESELLER'}, { id: 'HEPI_OPERATOR'},{id:'PROVIDER'}]
      } else if (role === 'RESELLER' || role === 'HEPI_RESELLER') {
        this.orgArr = this.orgArr.filter(function (prop) { return (prop.org_type === 'OPERATOR' || prop.org_type === 'HEPI_OPERATOR') })
        this.role_obj = [{ id: 'OPERATOR' }, { id: 'HEPI_OPERATOR'}]
      }
      this.title = 'Create User'
      this.username = ''
      this.first_name = ''
      this.last_name = ''
      this.password = ''
      this.org_name = ''
      this.role = ''
      this.provider =''
      this.phone_number = ''
      this.partner_code=''
      this.city = ''
      this.status = true
      this.set_moq = false
      this.free_credit = false
      this.sub_operators = false
      this.enable_vod = false
      this.ad_provision = false

      this.userNameErrors = []
      this.firstNameErrors = []
      this.lastNameErrors = []
      this.passwordErrors = []
      this.mobileNumberErrors = []
      this.roleErrors = []
      this.providerErrors = []


      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
      this.org_update_flag = false
    },
    edit (row) {
      var vm = this
      this.$http.get(config.menu.host + '/org/orgData/' + row.org_id + '/' + 'org_id' + '/null').then(resp => {
        var response = resp.body 
        var providerData = this.providerArr.filter(function(data){
            return data.provider_id == response.provider_id
        })
        this.provider = providerData[0]
      }) 
      row.provider = this.provider 
      this.title = 'Update User'
      this.user_id = row.user_id
      this.org_name = row.org_name
      this.first_name = row.first_name
      this.last_name = row.last_name
      this.username = row.username
      this.role = row.roles
      this.password = ''
      this.phone_number = row.phone_number
      this.partner_code = row.partner_code
      this.status = row.status ? true : false
      this.partner_code = row.partner_code
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
      this.org_update_flag = false
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllUser()
      this.passwordErrors = []
      this.firstNameErrors = []
      this.mobileNumberErrors = []
      this.providerErrors = []
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
<style>
  .checkbox {
    width: 40px;
    height: 22px;
    accent-color: #121212;
  }

  .va-checkbox__square:active input {
    background-color: #121212;
  }
</style>
